import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { getFormattedDayOfWeek } from '../../Utils/DateTime'
import WhiteButton from "../../Ui/WhiteButton";

const getDateForFirstEvent = (events) => {
  var evt = events[0]
  return new Date(evt?.document?.data?.start_time)
}
const getSceneForFirstEvent = (events) => {
  var evt = events[0]
  return evt?.document?.data?.stage?.document?.data?.title?.text
}

class HoverImage extends React.Component {
  constructor(props) {
      super(props);
      this.title = props.title;
      this.events = props.events;
      this.image = getImage(props.image);
      this.state = { hover: false };

      this.hasAtLeastOneEvent = props.events.length > 0
      this.hasMoreThanOneEvent = props.events.length > 1
  }

  setIsHoverTrue = () => {
      this.setState({ hover: true });
  };
  setIsHoverFalse = () => {
      this.setState({ hover: false });
  };

  render() {
      return (
          <div className="text-center z-0 group relative"
              onMouseOver={this.setIsHoverTrue}
              onFocus={this.setIsHoverTrue}
              onMouseOut={this.setIsHoverFalse}
              onBlur={this.setIsHoverFalse} >

              {/* IMAGE */}
              <div className="overflow-hidden rounded-t-2xl border-purple-wolf2025 border-t-[3px] border-x-[3px]">
                  <GatsbyImage
                      className="w-full h-full"
                      image={this.image}
                      alt={`Photo de ${this.title}`} />
              </div>

              {/* TEXT */}
              <div
                  className={`absolute w-full border-[3px] border-purple-wolf2025 rounded-b-2xl text-purple-wolf2025 font-obviously overflow-hidden transition-all duration-300
                      ${this.state.hover ? this.hasMoreThanOneEvent ? "h-36 -translate-y-24 bg-yellow-wolf2025 pt-0" : "h-24 -translate-y-12 bg-yellow-wolf2025 pt-2" : "h-12 bg-blue-wolf2025"} flex flex-col items-center justify-center`} >
                  <h2 className={`font-black uppercase tracking-wider text-lg leading-4 ${this.state.hover ? "pb-1" : "pb-0"}`}>{this.title}</h2>
                  
                  {
                      this.hasAtLeastOneEvent ?
                          <div className={`opacity-0 transition-all duration-300 py-0 ${this.state.hover ? "opacity-100 flex flex-col items-center justify-center" : "hidden" }`} >
                              <h3 className="!py-0 uppercase italic font-semibold">{getFormattedDayOfWeek(getDateForFirstEvent(this.events))}</h3>
                              <h4 className="!py-0 italic text-sm font-medium">{getSceneForFirstEvent(this.events)}</h4>
                              {
                                  this.hasMoreThanOneEvent ?
                                  <WhiteButton text="Autres représentations" className="px-5 mt-1 !py-1" />
                                  :
                                  <></>
                              }
                          </div>
                      :
                      <></>
                  }
              </div>
          </div>
      );
  }
}

const ArtistThumbnail = ({ image, title, events, to }) => {
  return (
    <a href={to}>
        <HoverImage image={image} title={title} events={events} />
    </a>
  )
}

export default ArtistThumbnail
